import React from "react";

const InputField = (props) => {
  let {
    type,
    placeholder,
    name,
    value,
    error,
    onChange,
    readOnly,
    label,
    className,
    id,
    required,
    defaultValue,
    onFocus,
    onBlur,
    onKeyPress = () => {},
    disabled,
    autocomplete,
  } = props;
  return (
    <div className="form-group">
      {label ? (
        <label htmlFor={id}>
          {label} {required ? <sup>*</sup> : null}
        </label>
      ) : null}
      <input
        maxLength={50}
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        className={className}
        defaultValue={defaultValue}
        id={id}
        readOnly={readOnly}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        disabled={disabled}
        autoComplete={autocomplete}
      />
      {error && <div className="help-block error text-danger">{error}</div>}
    </div>
  );
};

export default InputField;
