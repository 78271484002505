export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function getParameters(urlString) {
  const res = {};
  let paramString = urlString.split("?")[1];
  let params_arr = paramString.split("&");
  for (let i = 0; i < params_arr.length; i++) {
    let pair = params_arr[i].split("=");
    res[pair[0]] = pair[1];
  }
  return res;
}
export function encodeUri(str) {
  return btoa(encodeURIComponent(str));
}
export function decodeUri(str) {
  return decodeURIComponent(atob(str));
}

export const passwordRegx = /^(?=.{6,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).*$/;

export const passwordConstraints = "Please create a password of six or more characters that has a mix of uppercase, lowercase, numbers, and symbols (like ! and &).";

export const privacyNotice = "Your information will be processed as described in the Ivory Privacy Policy."
