import Validator from "is_js";
import { isEmpty } from "lodash";
import { passwordRegx, passwordConstraints } from "../utils";
const confirmationCodeRegex = /^[0-9]{6}$/;
function validateResetFields(data) {
  let errors = {};

  if (Validator.empty(data.password)){
    errors.password = passwordConstraints;
  }else if (data.password.length < 6) {
    errors.password = passwordConstraints;
  }else if (!passwordRegx.test(data.password)){ 
    errors.password = passwordConstraints;
  }

  if (Validator.empty(data.confirmationCode)) {
    errors.confirmationCode = "A confirmation code is required";
  } else if (!confirmationCodeRegex.test(data.confirmationCode)){
    errors.confirmationCode = "The confirmation code must be exactly six numbers";
  }

  return {
    isValid: isEmpty(errors),
    errors,
  };
}

export default validateResetFields;
